<template>
  <div class="container is-max-tablet">
    <h3 class="title">Om Patientkørsel</h3>
    <p class="is-size-5">
      Velkommen til Patientkørsel, en tjeneste udviklet af Det grønlandske Patienthjem. Formålet med denne tjeneste er
      at lette arbejdsgangene i patienthjemmet og sikre, at vores patienter får den bedst mulige oplevelse, når de skal
      til deres lægeaftaler.
    </p>
    <p class="is-size-5">
      Med Patientkørsel kan patienter nemt registrere deres lægeaftaler og automatisk blive placeret på de rigtige
      bustider, så de kommer til hospitalet i god tid. Tjenesten gør det også muligt at angive, om man har brug for en
      tolk, hvilket hjælper tolkene med at planlægge, hvilke hospitaler de skal tolke på og hvornår.
    </p>
    <p class="is-size-5">
      Patienter kan selv indtaste oplysninger om deres aftaler, eller de kan få hjælp af personalet til at gøre det.
    </p>
  </div>
</template>

<script>
export default {
  name: 'AboutPage',
};
</script>

<style scoped></style>
